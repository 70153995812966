import Template from "../components/Template";
import React, { useEffect, useState } from "react";
import {
  Button,
  Confirm,
  Dropdown,
  Form,
  Icon,
  Input,
  Label,
  Pagination,
  PaginationProps,
  Popup,
  Table,
} from "semantic-ui-react";
import { useActions } from "../redux/actions/UseActions";
import * as PatientActions from "../redux/actions/PatientActions";
import * as PracticeActions from "../redux/actions/PracticeActions";
import { Patient } from "../models/Patient";
import { PageRequest, PageResponse } from "../models/Pagination";
import moment from "moment";
import {
  PatientStatus,
  STATUS_COLOR_MAP,
  STATUS_MESSAGE_MAP,
} from "../models/PatientStatus";
import _ from "lodash";
import Loader from "../components/Loader";
import { convertToCSV, exportedStatus } from "../utils/common";
import DatePicker from "react-datepicker";
import PatientModal from "../components/PatientModal";
import { HelpMark } from "../components/help/HelpMark";
import { HELP_PAGE } from "../utils/HELP_PAGE";
import { parsePatientData } from "../utils/converter";
import { CARRIER_LIST } from "../utils/constants";

const Patients = () => {
  const patientActions = useActions(PatientActions);
  const practiceActions = useActions(PracticeActions);
  const [pagedPatients, setPagedPatients] = useState<PageResponse<Patient>>({
    data: [],
    count: 0,
  });
  const [practiceList, setPracticeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<Patient | undefined>(
    undefined,
  );
  const initial = {
    start: moment().startOf("M").toDate(),
    end: moment().endOf("M").toDate(),
  };
  const [dateRange, setDateRange] = useState([initial.start, initial.end]);
  const [startDate, endDate] = dateRange;
  const [pageRequest, setPageRequest] = useState<PageRequest>({
    limit: 14,
    offset: 0,
    orderBy: "",
    where: {
      practiceId: [],
      createdAt: [
        moment(initial.start).format("yyyy-MM-DD"),
        moment(initial.end).add(1, "day").format("YYYY-MM-DD"),
      ],
    },
  });

  useEffect(() => {
    loadPractices().then();
  }, []);

  useEffect(() => {
    loadPatients().then();
  }, [pageRequest]);

  const loadPatients = async () => {
    setLoading(true);
    const pagedData = await patientActions.getPatients(pageRequest);
    pagedData.data = pagedData.data.map((d: Patient) => parsePatientData(d));
    setPagedPatients(pagedData);
    setLoading(false);
  };

  const loadPractices = async () => {
    const practices = await practiceActions.getPractices();
    const formattedPractices = practices?.data?.map((practice: any) => ({
      key: practice.id,
      value: practice.id,
      text: practice.practice,
    }));
    formattedPractices && setPracticeList(formattedPractices);
  };

  const handleSort = (headerName: string, isAscending: boolean) => {
    setPageRequest({
      ...pageRequest,
      orderBy: [headerName, isAscending ? "DESC" : "ASC"].join(","),
    });
  };

  const removeSort = () => {
    setPageRequest({
      ...pageRequest,
      orderBy: "",
    });
  };

  const getSorting = (headerName: string, headerDisplay: string) => {
    const isAscending = pageRequest.orderBy?.split(",")[1] === "ASC";
    const iconColor = headerName === pageRequest.orderBy ? "blue" : "black";
    const iconDirection = isAscending ? "sort amount up" : "sort amount down";
    const removeSortContent = "Remove sorting on this column";

    return (
      <div>
        {headerDisplay}
        <Icon
          className="cursorPointer"
          color={iconColor}
          name={iconDirection}
          onClick={() => handleSort(headerName, isAscending)}
        />
        <Popup
          position="bottom left"
          basic
          content={removeSortContent}
          trigger={
            headerName === pageRequest.orderBy && (
              <Button
                icon
                size="mini"
                basic={true}
                color="red"
                onClick={() => removeSort()}
              >
                <Icon name="trash alternate" />
              </Button>
            )
          }
        />
      </div>
    );
  };

  const onPageChange = (data: PaginationProps) => {
    setPageRequest({
      ...pageRequest,
      offset: (+(data.activePage ?? 1) - 1) * pageRequest.limit,
      limit: pageRequest.limit,
    });
  };

  const updateToRetry = async (id: number) => {
    setLoading(true);
    setSelectedPatient(undefined);
    await patientActions.updatePatientStatus(id, PatientStatus.RETRY);
    await loadPatients();
  };

  const onChange = (value: any, property: string) => {
    const whereClause = pageRequest.where;
    whereClause[property] = value;
    const where = Object.fromEntries(
      Object.entries(whereClause).filter(([, value]) => value !== null),
    );
    setPageRequest({ ...pageRequest, where: where, offset: 0 });
  };

  const onDateSelect = (dates: any[]) => {
    const [start, end] = dates.map((date) => {
      return date ? moment(date).format("YYYY-MM-DD") : null;
    });
    const endDate = moment(end).add(1, "day").format("YYYY-MM-DD");
    const paramsDate: any = { startDate: start, endDate: endDate };
    const params = Object.values(paramsDate).join();
    if (!start && !end) {
      delete pageRequest.where.createdAt;
      loadPatients().then();
    } else onChange(params, "createdAt");
  };

  const delayedOnChangeInput = _.debounce(onChange, 1500);

  const exportTableToCSV = async (markAsExported: boolean) => {
    setLoading(true);
    setIsConfirmOpen(false);
    const response = await patientActions.getPatients({
      where: pageRequest.where,
      orderBy: pageRequest.orderBy,
      markAsExported: markAsExported ? 1 : 0,
    });
    const dataToExport = response?.data.map((record: any) => {
      const patient = parsePatientData(record);
      let age;
      if (moment(patient.dateOfBirth).isValid()) {
        const months = moment().diff(moment(patient.dateOfBirth), "months");
        age = Math.round((months / 12) * 10) / 10;
      }
      return {
        PracticeName: patient.practice?.practice,
        MCO: patient.mco,
        Subscriber_ID: patient.subscriberId,
        patNum: patient.patNum,
        firstName: patient.firstName,
        lastName: patient.lastName,
        firstVisit: patient.firstVisit,
        nextApt: patient.nextApt,
        dateOfBirth: patient.dateOfBirth,
        age: age ?? "",
        Address1: patient.parsedData?.Address1,
        Phone: patient.parsedData?.Phone,
        Status: patient.status,
        Extracted: patient.parsedData?.First_Extract_Date,
        datePosted: moment(patient.createdAt).format("DD MMMM YYYY @HH:mm"),
      };
    });
    onChange(markAsExported ? 1 : 0, "exportedAt");
    if (response?.count == 0) {
      alert("There's no data to export!!");
      return;
    }

    const headers = [
      "Practice",
      "MCO",
      "Subscriber ID",
      "Pat Num",
      "First Name",
      "Last Name",
      "First Visit",
      "Last Apt",
      "Date of Birth",
      "Age",
      "Address",
      "Phone",
      "Status",
      "Extracted Date",
      "Created",
    ];

    const csvContent = convertToCSV(headers, dataToExport);
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `patients_data_${moment().format("DD-MMMM-YYYY")}.csv`,
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Template activeLink="dashboard">
      {loading && <Loader></Loader>}
      <div>
        <h1>
          Patients ({pagedPatients?.count}){" "}
          <HelpMark helpPage={HELP_PAGE.Patient} />
        </h1>

        <Form>
          <Form.Group inline={true} className="form-inline-stacked-group">
            <Form.Field>
              <Dropdown
                placeholder="Practice"
                search
                clearable
                multiple
                selection
                options={practiceList}
                value={pageRequest.where.practiceId}
                onChange={(e, data: any) => onChange(data.value, "practiceId")}
              />
            </Form.Field>
            <Form.Field>
              <Dropdown
                placeholder="Status"
                search
                clearable
                multiple
                selection
                options={Object.keys(PatientStatus).map((key) => ({
                  text: key,
                  value: key,
                  key,
                }))}
                onChange={(e, data: any) => onChange(data.value, "status")}
              />
            </Form.Field>
            <Form.Field className="range">
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd MMM yyyy"
                placeholderText="Select Date Range"
                onChange={(update: any) => {
                  onDateSelect(update);
                  setDateRange(update);
                }}
                isClearable={true}
              />
            </Form.Field>
            <Form.Field>
              <Dropdown
                placeholder="MCO"
                search
                clearable
                multiple
                selection
                options={CARRIER_LIST.map((c) => ({
                  key: c + "_option",
                  text: c,
                  value: c,
                }))}
                onChange={(e, data: any) => onChange(data.value, "mco")}
              />
            </Form.Field>
            <Form.Field>
              <Input
                type="text"
                placeholder="Patient Number"
                onChange={(e, data: any) =>
                  delayedOnChangeInput(data.value, "patNum")
                }
              />
            </Form.Field>
            <Form.Field>
              <Input
                type="text"
                placeholder="First Name"
                onChange={(e, data: any) =>
                  delayedOnChangeInput(data.value, "firstName")
                }
              />
            </Form.Field>
            <Form.Field>
              <Input
                type="text"
                placeholder="Last Name"
                onChange={(e, data: any) =>
                  delayedOnChangeInput(data.value, "lastName")
                }
              />
            </Form.Field>
            <Form.Field>
              <Input
                type="text"
                placeholder="Subscriber ID"
                onChange={(e, data: any) =>
                  delayedOnChangeInput(data.value, "subscriberId")
                }
              />
            </Form.Field>
            <Form.Field>
              <Dropdown
                placeholder="Export"
                search
                clearable
                selection
                options={exportedStatus}
                value={pageRequest.where.exportedAt}
                onChange={(e, data: any) =>
                  onChange(data.value < 0 ? null : data.value, "exportedAt")
                }
              />
            </Form.Field>
            <Form.Field>
              <Button
                primary
                content="Export"
                onClick={() => setIsConfirmOpen(true)}
              />
              <Confirm
                open={isConfirmOpen}
                content={
                  <h3 className="text-center p-2">{`Export ${
                    pagedPatients?.count ?? 0
                  } patients and MARK as Exported Patients`}</h3>
                }
                cancelButton="Export Only"
                confirmButton="Mark as Exported"
                onCancel={(e) =>
                  (e.target as any).tagName == "BUTTON"
                    ? exportTableToCSV(false)
                    : setIsConfirmOpen(false)
                }
                onConfirm={() => exportTableToCSV(true)}
              />
            </Form.Field>
          </Form.Group>
        </Form>

        <Table striped={true} compact={true} selectable={true}>
          <Table.Header>
            <Table.HeaderCell />
            <Table.HeaderCell content="Practice" />
            <Table.HeaderCell content={getSorting("mco", "MCO ")} />
            <Table.HeaderCell
              content={getSorting("subscriberId", "Subscriber ID ")}
            />
            <Table.HeaderCell content={getSorting("patNum", "Pat Num ")} />
            <Table.HeaderCell content={getSorting("firstName", "First Name")} />
            <Table.HeaderCell content={getSorting("lastName", "Last Name")} />
            <Table.HeaderCell content="Address" />
            <Table.HeaderCell content="Phone" />
            <Table.HeaderCell content="First Visit" />
            <Table.HeaderCell content="Next Apt" />
            <Table.HeaderCell content={getSorting("status", "Status ")} />
            <Table.HeaderCell content="Extracted Date" />
            <Table.HeaderCell
              content={getSorting("updatedAt", "Last Updated ")}
            />
          </Table.Header>
          <Table.Body>
            {pagedPatients?.data.map((patient: Patient) => (
              <Table.Row key={patient.id}>
                <Table.Cell>
                  <Button
                    icon="eye"
                    primary
                    size="mini"
                    onClick={() => setSelectedPatient(patient)}
                  />
                  {patient.exportedAt && (
                    <Button
                      className="cursorPointer"
                      size="mini"
                      color="grey"
                      title={`Exported At - ${moment(patient.exportedAt).format(
                        "DD MMM YYYY",
                      )}`}
                      icon="info"
                    />
                  )}
                </Table.Cell>
                <Table.Cell content={patient.practice?.practice} />
                <Table.Cell content={patient.mco} />
                <Table.Cell content={patient.subscriberId} />
                <Table.Cell content={patient.patNum} />
                <Table.Cell content={patient.firstName} />
                <Table.Cell content={patient.lastName} />
                <Table.Cell content={patient.parsedData?.Address1} />
                <Table.Cell content={patient.parsedData?.Phone} />
                <Table.Cell
                  content={
                    (patient?.firstVisit &&
                      moment(patient.firstVisit).isValid() &&
                      moment(patient.firstVisit).format("DD MMM YYYY")) ||
                    "-"
                  }
                />
                <Table.Cell
                  content={
                    (patient?.nextApt &&
                      moment(patient.nextApt).isValid() &&
                      moment(patient.nextApt).format("DD MMM YYYY")) ||
                    "-"
                  }
                />
                <Table.Cell>
                  <Label
                    className="cursorPointer"
                    content={patient.status}
                    color={STATUS_COLOR_MAP[patient.status] ?? "grey"}
                    title={STATUS_MESSAGE_MAP[patient.status]}
                  />
                  {patient.status == PatientStatus.FAILED && (
                    <Button
                      basic={true}
                      onClick={() => updateToRetry(patient.id)}
                      color="green"
                      size="mini"
                      content="Retry"
                    />
                  )}
                </Table.Cell>
                <Table.Cell
                  content={moment(
                    patient.parsedData?.First_Extract_Date,
                  ).format("DD MMM YYYY")}
                />
                <Table.Cell
                  content={moment(patient.updatedAt).format(
                    "DD MMM YYYY @HH:mm",
                  )}
                />
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {!pagedPatients?.data?.length && (
          <p className="text-center">
            <b>No data found in selection</b>
          </p>
        )}
        <Pagination
          onPageChange={(_, data) => onPageChange(data)}
          activePage={Math.ceil(pageRequest?.offset / pageRequest?.limit) + 1}
          totalPages={Math.ceil(pagedPatients?.count / pageRequest?.limit)}
        />
        {selectedPatient && (
          <PatientModal
            onRetry={updateToRetry}
            patient={selectedPatient}
            onClose={() => setSelectedPatient(undefined)}
          />
        )}
      </div>
    </Template>
  );
};

export default Patients;
