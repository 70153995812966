import Template from "../components/Template";
import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "semantic-ui-react";
import { useActions } from "../redux/actions/UseActions";
import * as McoPatPlanConfigActions from "../redux/actions/McoPatPlanConfigActions";
import * as PracticeActions from "../redux/actions/PracticeActions";
import { PageRequest, PageResponse } from "../models/Pagination";
import { Practice } from "../models/Practice";
import { HelpMark } from "../components/help/HelpMark";
import { HELP_PAGE } from "../utils/HELP_PAGE";
import ConfigModal from "../components/McoPatPlanConfigurations/ConfigModal";
import { McoPatPlanConfig } from "../models/McoPatPlanConfig";
import ConfigTable from "../components/McoPatPlanConfigurations/ConfigTable";

const McoPatPlanConfigurations = () => {
  const mcoPatPlanConfigActions = useActions(McoPatPlanConfigActions);
  const practiceActions = useActions(PracticeActions);
  const [pagedApiConfigs, setPagedMcoPatPlanConfigs] = useState<
    PageResponse<McoPatPlanConfig>
  >({
    data: [],
    count: 0,
  });
  const [practices, setPractices] = useState<PageResponse<Practice>>({
    data: [],
    count: 0,
  });
  const [pageRequest, setPageRequest] = useState<PageRequest>({
    limit: 100,
    offset: 0,
    where: {},
  });
  const { limit, offset } = pageRequest;
  const [editMode, setEditMode] = useState(false);
  const [currentConfig, setCurrentConfig] = useState({} as McoPatPlanConfig);
  const [state, setState] = useState({
    openModal: false,
  });

  const closeModal = async () => {
    setState({ openModal: false });
    setCurrentConfig({} as McoPatPlanConfig);
    setEditMode(false);
    await loadMcoPatPlanConfigs();
  };
  useEffect(() => {
    loadMcoPatPlanConfigs();
    loadPractices();
  }, []);
  useEffect(() => {
    loadMcoPatPlanConfigs();
  }, [pageRequest]);
  const loadMcoPatPlanConfigs = async () => {
    setLoading(true);
    const data: PageResponse<McoPatPlanConfig> =
      await mcoPatPlanConfigActions.getMcoPatPlanConfigs({
        limit,
        offset,
        where: pageRequest.where,
      });
    data && setPagedMcoPatPlanConfigs(data);
    setLoading(false);
  };
  const loadPractices = async () => {
    setLoading(true);
    const data = await practiceActions.getPractices();
    data && setPractices(data);
    setLoading(false);
  };

  const editConfig = (mcoPatPlanConfig: McoPatPlanConfig) => {
    setCurrentConfig(mcoPatPlanConfig);
    setEditMode(true);
    setState({ openModal: true });
  };

  const [loading, setLoading] = useState(true);
  const [selectedPractice, setSelectedPractice] = useState("");
  const handleSelectInputChange = (
    event: React.SyntheticEvent<HTMLElement>,
    data: any,
  ) => {
    setSelectedPractice(data.value);
    setPageRequest({ ...pageRequest, where: { practiceId: data.value } });
  };

  let practiceOptions = practices?.data.map((practice, key) => ({
    text: practice.practice,
    value: practice.id,
    key,
  }));
  let availablePractices: Practice[] = [];
  if (practiceOptions) {
    if (practiceOptions[0]?.text !== "All Practices") {
      practiceOptions?.unshift({
        text: "All Practices",
        value: 0,
        key: 0,
      });
    }
    availablePractices = practices.data;
  }

  return (
    <Template activeLink="dashboard" loading={loading}>
      <div>
        <div className="ui card" style={{ padding: "10px", width: "100%" }}>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div>
              <h1>
                MCO PatPlan Configurations
                <HelpMark helpPage={HELP_PAGE.Mco_PatPlan_Configurations} />
              </h1>
            </div>
            <div
              style={{
                float: "right",
                display: "flex",
                columnGap: "10px",
                width: "25%",
              }}
            >
              <Dropdown
                search={true}
                disabled={editMode}
                required
                placeholder="Select Practice"
                fluid
                selection
                options={practiceOptions}
                value={selectedPractice}
                onChange={(e, data) => handleSelectInputChange(e, data)}
              />
              <Button
                content="Create"
                onClick={() => setState({ openModal: true })}
                primary
              />
            </div>
          </div>
        </div>

        <ConfigTable editConfig={editConfig} data={pagedApiConfigs.data} />
      </div>

      <ConfigModal
        open={state.openModal}
        onClose={closeModal}
        practices={availablePractices}
        editMode={editMode}
        data={currentConfig}
      />
    </Template>
  );
};
export default McoPatPlanConfigurations;
