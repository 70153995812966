import React, { FC } from "react";
import { Modal } from "semantic-ui-react";

interface ViewFileModalProps {
  files: string;
  closeModal: () => void;
  type: string;
}

export const ViewFileModal: FC<ViewFileModalProps> = ({
  files,
  closeModal,
  type,
}) => {
  const selectedFiles =
    files && files !== ""
      ? files
          .split(",")
          .filter((file) =>
            file.toLowerCase().includes(type === "Video" ? ".mp4" : ".pdf"),
          )
      : [];

  return (
    <Modal
      open={true}
      onClose={closeModal}
      aria-labelledby="ModalHeader"
      closeIcon
      size="large"
    >
      <Modal.Header>
        {type === "Video" ? "Watch Video" : "View PDF"}
      </Modal.Header>
      <Modal.Content scrolling>
        <div className="row">
          <div className="col-12 text-center">
            {selectedFiles.length > 0 ? (
              selectedFiles.map((file, key) => (
                <div key={key}>
                  {file.includes(".mp4") ? (
                    <video className="helpVideo" controls>
                      <source src={file} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <div className="helpPdf">
                      <a
                        className="primaryColor mb-3 displayBlock"
                        target="_blank"
                        href={file}
                        rel="noreferrer"
                      >
                        Open in full screen
                      </a>
                      <iframe title={`pdf-${key}`} src={file} />
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div>No file found.</div>
            )}
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};
