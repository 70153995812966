import {
  Button,
  Icon,
  Label,
  LabelGroup,
  Popup,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import React from "react";
import moment from "moment/moment";
import { McoPatPlanConfig } from "../../models/McoPatPlanConfig";
import Loader from "../Loader";

interface Props {
  data: McoPatPlanConfig[];
  editConfig: (McoPatPlanConfig: McoPatPlanConfig) => void;
}

const ConfigTable: React.FC<Props> = (props) => {
  if (!props.data?.length) return <Loader />;

  const mcoTypes = new Set<string>();
  const data = props.data.reduce(
    (acc, item) => {
      if (!acc[item.practice?.practice ?? "-"]) {
        acc[item.practice?.practice ?? "-"] = [];
      }
      mcoTypes.add(item.mco);
      acc[item.practice?.practice ?? "-"].push(item);
      return acc;
    },
    {} as { [key: string]: McoPatPlanConfig[] },
  );

  return (
    <>
      <Table color={"orange"} compact striped>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>Practice</TableHeaderCell>
            {Array.from(mcoTypes).map((practiceId) => (
              <TableHeaderCell>{practiceId}</TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.entries(data)
            .sort(([x], [y]) => x.localeCompare(y))
            .map(([practice, item], index: number) => {
              return (
                <TableRow key={index + "practice"}>
                  <TableCell content={practice} />
                  {Array.from(mcoTypes).map((mco) => {
                    const config = item.find((i) => i.mco == mco);
                    if (!config) return <TableCell content="-" />;

                    return (
                      <TableCell>
                        <Icon
                          name="edit"
                          className="cursor-pointer mr10"
                          onClick={() => props.editConfig(config)}
                        />
                        {config.planDetails?.CarrierName ?? "???"} - #
                        {config.patPlan}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </>
  );
};

export default ConfigTable;
