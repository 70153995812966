import React, { useEffect, useState } from "react";
import { Modal, Form, Dropdown, Input } from "semantic-ui-react";
import * as McoPatPlanConfigActions from "../../redux/actions/McoPatPlanConfigActions";
import { useActions } from "../../redux/actions/UseActions";
import Swal from "sweetalert2";
interface ApiConfigurationModalProps {
  open: boolean;
  onClose: any;
  practices: Array<any>;
  editMode: boolean;
  data: any;
}
const ConfigModal = (props: ApiConfigurationModalProps) => {
  const { onClose, open, practices, data, editMode } = props;
  const [selectedPractice, setSelectedPractice] = useState("");
  const [mco, setMco] = useState("");
  const [patPlan, setPatPlan] = useState("");
  useEffect(() => {
    if (editMode && data) {
      setPatPlan(data?.patPlan);
      setSelectedPractice(data?.practiceId);
      setMco(data?.mco);
    }
  }, [data]);
  const practiceOptions = practices.map((practice, key) => ({
    text: practice.practice,
    value: practice.id,
    key,
  }));
  const mcoPatPlanConfigActions = useActions(McoPatPlanConfigActions);
  const handleSubmit = async () => {
    const result = await mcoPatPlanConfigActions.saveMcoPatPlanConfig({
      practiceId: selectedPractice,
      mco: mco,
      patPlan: patPlan,
    });
    if (result?.created) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "A new configuration has been successfully created.",
        showConfirmButton: false,
        timer: 4000,
        toast: true,
        background: "#105063",
        color: "white",
      });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title:
          "The creation of a new configuration failed. Please verify whether a configuration for the same practice already exists.",
        showConfirmButton: false,
        timer: 6000,
        toast: true,
        background: "#932121",
        color: "white",
      });
    }
    onModalClose();
    clear();
  };
  const handleUpdate = async () => {
    const result = await mcoPatPlanConfigActions.updateConfiguration({
      practiceId: selectedPractice,
      mco: mco,
      patPlan: patPlan,
    });
    if (result?.updated) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Api configuration has been updated successfully.",
        showConfirmButton: false,
        timer: 4000,
        toast: true,
        background: "#105063",
        color: "white",
      });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Failed to update the API configuration.",
        showConfirmButton: false,
        timer: 5000,
        toast: true,
        background: "#932121",
        color: "white",
      });
    }
    onModalClose();
    clear();
  };
  const clear = () => {
    setSelectedPractice("");
    setMco("");
    setPatPlan("");
  };
  const onModalClose = () => {
    onClose();
    clear();
  };
  const handleSelectInputChange = (
    event: React.SyntheticEvent<HTMLElement>,
    data: any,
  ) => {
    setSelectedPractice(data.value);
  };
  return (
    <Modal
      open={open}
      onClose={onModalClose}
      size="small"
      closeOnEscape={true}
      closeIcon={true}
    >
      <Modal.Header>
        {editMode ? `Edit` : `Add`} MCO PatPlan Configuration Form
      </Modal.Header>
      <Modal.Content>
        <Form onSubmit={() => (editMode ? handleUpdate() : handleSubmit())}>
          <Form.Field>
            <label>Select Practice</label>
            <Dropdown
              search
              disabled={editMode}
              required
              placeholder="Select Practice"
              fluid
              selection
              options={practiceOptions}
              value={selectedPractice}
              onChange={(e, data) => handleSelectInputChange(e, data)}
            />
          </Form.Field>
          <Form.Field>
            <label>MCO</label>
            <Input
              required
              type="text"
              placeholder="MCO"
              value={mco}
              onChange={(e) => setMco(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>PatPlan</label>
            <Input
              type="text"
              required
              placeholder="123456"
              value={patPlan}
              onChange={(e) => setPatPlan(e.target.value)}
            />
          </Form.Field>
          {editMode ? (
            <Form.Button primary onClick={() => handleUpdate()}>
              Save Changes
            </Form.Button>
          ) : (
            <Form.Button primary type="submit">
              Submit
            </Form.Button>
          )}
        </Form>
      </Modal.Content>
    </Modal>
  );
};
export default ConfigModal;
