import React, { useEffect, useState } from "react";
import { Modal, Form, Dropdown, Input, Icon } from "semantic-ui-react";
import * as OpenDentalApiConfigActions from "../../redux/actions/OpenDentalApiConfigActions";
import { useActions } from "../../redux/actions/UseActions";
import Swal from "sweetalert2";

interface ApiConfigurationModalProps {
  open: boolean;
  onClose: any;
  practices: Array<any>;
  editMode: boolean;
  data: any;
}

const ApiConfigurationModal = (props: ApiConfigurationModalProps) => {
  const { onClose, open, practices, data, editMode } = props;
  const [selectedPractice, setSelectedPractice] = useState("");
  const [url, setUrl] = useState("");
  const [authentication, setAuthentication] = useState("");

  useEffect(() => {
    if (editMode && data) {
      setAuthentication(data?.authentication);
      setSelectedPractice(data?.practiceId);
      setUrl(data?.url);
    }
  }, [data]);

  const practiceOptions = practices.map((practice, key) => ({
    text: practice.practice,
    value: practice.practiceId,
    key,
  }));
  const openDentalApiConfigActions = useActions(OpenDentalApiConfigActions);
  const handleSubmit = async () => {
    const result = await openDentalApiConfigActions.saveOpenDentalApiConfig({
      practiceId: selectedPractice,
      url,
      authentication,
    });
    if (result?.created) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "A new Api configuration has been successfully created.",
        showConfirmButton: false,
        timer: 4000,
        toast: true,
        background: "#105063",
        color: "white",
      });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title:
          "The creation of a new Api configuration failed. Please verify whether a configuration for the same practice already exists.",
        showConfirmButton: false,
        timer: 6000,
        toast: true,
        background: "#932121",
        color: "white",
      });
    }
    onModalClose();
    clear();
  };

  const handleUpdate = async () => {
    const { id } = data;
    const result = await openDentalApiConfigActions.updateConfiguration(id, {
      practiceId: selectedPractice,
      url,
      authentication,
    });
    if (result?.updated) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Api configuration has been updated successfully.",
        showConfirmButton: false,
        timer: 4000,
        toast: true,
        background: "#105063",
        color: "white",
      });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Failed to update the API configuration.",
        showConfirmButton: false,
        timer: 5000,
        toast: true,
        background: "#932121",
        color: "white",
      });
    }
    onModalClose();
    clear();
  };

  const clear = () => {
    setSelectedPractice("");
    setUrl("");
    setAuthentication("");
  };

  const onModalClose = () => {
    onClose();
    clear();
  };

  const handleSelectInputChange = (
    event: React.SyntheticEvent<HTMLElement>,
    data: any,
  ) => {
    setSelectedPractice(data.value);
  };

  return (
    <Modal
      open={open}
      onClose={onModalClose}
      size="small"
      closeOnEscape={true}
      closeIcon={true}
    >
      <Modal.Header>
        {editMode ? `Edit` : `Add`} Api Configuration Form
      </Modal.Header>
      <Modal.Content>
        <Form onSubmit={() => handleSubmit()}>
          <Form.Field>
            <label>Select Practice</label>
            <Dropdown
              search
              disabled={editMode}
              required
              placeholder="Select Practice"
              fluid
              selection
              options={practiceOptions}
              value={selectedPractice}
              onChange={(e, data) => handleSelectInputChange(e, data)}
            />
          </Form.Field>
          <Form.Field>
            <label>URL</label>
            <Input
              required
              type="url"
              placeholder="Enter URL"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>
              Authentication Key
              <br />
              <span style={{ fontWeight: 100 }}>
                <Icon name="info circle" /> ODFHIR{" "}
                {"{DeveloperKey}/{CustomerKey}"}
              </span>
            </label>

            <Input
              type="password"
              required
              placeholder="Enter Authentication Key"
              value={authentication}
              className="mt-4"
              onChange={(e) => setAuthentication(e.target.value)}
            />
          </Form.Field>
          {editMode ? (
            <Form.Button primary onClick={() => handleUpdate()}>
              Save Changes
            </Form.Button>
          ) : (
            <Form.Button primary type="submit">
              Submit
            </Form.Button>
          )}
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default ApiConfigurationModal;
